export async function addSuffixToSheetName (context, tabName) {
  const sheets = context.workbook.worksheets.load('items/name')
  await context.sync()

  let sheetName = tabName
  let suffix = 1
  while (sheets.items.some((sheet) => sheet.name === sheetName)) {
    sheetName = `${tabName} (${suffix++})`
  }
  return sheetName
}

export function getUrl () {
  return window.location.origin
}
