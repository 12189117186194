import {
  applyFormatting,
  applyOuterBorder,
  setFixedColumnWidth,
  applyTitleFormatting,
  setDropdownAndOptionalColour
} from '@/components/Ribbons/Calculators/commonFormatting'
import { VV_UNIT_SIZES_DROPDOWN, VV_FIXED_COL_WIDTH } from '@/lib/constants'
import { antecSecondary, antecPrimary } from '@/main'
import store from '@/store'

export function btnvvsoundcalc (event) {
  window.Excel.run(async (context) => {
    try {
      await store.dispatch('units/loadSelectedUnits')
      const flowUnits = store.getters['units/getUnits']('flow')
      const pressureUnits = store.getters['units/getUnits']('pressure')

      // Define the table structure
      const tableData = [
        ['VV Sound Performance Calculator', '', ''],
        ['', '', ''],
        ['Unit Size', '', ''],
        ['Max Design Flow', '', flowUnits],
        ['Pressure Drop', '', pressureUnits],
        ['', '', ''],
        ['', 'Sound Power Levels', ''],
        ['Max Discharge, Lw', '', ''],
        ['Max Radiated, Lw', '', ''],
        ['Max Inlet, Lw', '', '']
      ]

      const activeCell = context.workbook.getActiveCell()
      const numRows = tableData.length
      const numCols = tableData[0].length
      const targetRange = activeCell.getResizedRange(numRows - 1, numCols - 1)

      const unitSizeCell = targetRange.getCell(2, 1)
      const maxAirflowCell = targetRange.getCell(3, 1)
      const pressureDropCell = targetRange.getCell(4, 1)
      const maxDischargeCell = targetRange.getCell(7, 1)
      const maxRadiatedCell = targetRange.getCell(8, 1)
      const maxInletCell = targetRange.getCell(9, 1)

      // Clear contents before loading
      targetRange.clear()
      targetRange.values = tableData

      // Style calculator background
      const whiteBackground = targetRange.getCell(1, 0).getResizedRange(numRows - 2, numCols - 1)
      applyFormatting(whiteBackground, 'white')
      const blackTable = targetRange.getCell(6, 0).getResizedRange(3, numCols - 1)
      applyFormatting(blackTable, 'black')
      applyOuterBorder(targetRange, 'black', 'Thin')

      // Style title
      const titleRange = targetRange.getCell(0, 0).getResizedRange(0, numCols - 1)
      titleRange.merge()
      applyTitleFormatting(titleRange, antecPrimary, 'white')
      applyFormatting(titleRange, 'black', 'Thin')

      // Style input & output cells
      const inputRange = unitSizeCell.getResizedRange(2, 0)
      applyFormatting(inputRange, 'black', 'Medium')
      inputRange.format.fill.color = antecSecondary // Darker grey for input fields
      inputRange.format.horizontalAlignment = 'center'
      inputRange.format.verticalAlignment = 'center'

      const rowsToMerge = [
        { row: 1, columns: 0, width: 2 },
        { row: 5, columns: 0, width: 2 },
        ...[6, 7, 8, 9].map(row => ({ row, columns: 1, width: 1 }))
      ]

      rowsToMerge.forEach(({ row, columns, width }) => {
        const mergeRange = targetRange.getCell(row, columns).getResizedRange(0, width)
        mergeRange.merge()
        mergeRange.format.horizontalAlignment = 'center'
      })

      // Style column width
      targetRange.format.autofitColumns()
      setFixedColumnWidth(targetRange, numCols, VV_FIXED_COL_WIDTH)

      // Set dropdowns
      setDropdownAndOptionalColour(unitSizeCell, VV_UNIT_SIZES_DROPDOWN)

      // Load formulas
      unitSizeCell.load('address')
      maxAirflowCell.load('address')
      pressureDropCell.load('address')

      await context.sync()

      const unitSizeAddress = unitSizeCell.address
      const maxAirflowAddress = maxAirflowCell.address
      const pressureDropAddress = pressureDropCell.address

      maxDischargeCell.formulas = [[`=AntecControls.VV.MAXDISCHARGE(${unitSizeAddress}, ${maxAirflowAddress}, ${pressureDropAddress})`]]
      maxRadiatedCell.formulas = [[`=AntecControls.VV.MAXRADIATED(${unitSizeAddress}, ${maxAirflowAddress}, ${pressureDropAddress})`]]
      maxInletCell.formulas = [[`=AntecControls.VV.MAXINLET(${unitSizeAddress}, ${maxAirflowAddress}, ${pressureDropAddress})`]]
    } catch (error) {
      console.error('Error: ', error)
    }
    event.completed()
  })
}
