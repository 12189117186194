export async function saveToCustomProperties (key, unitsType) {
  try {
    await window.Excel.run(async (context) => {
      const customDocProperties = context.workbook.properties.custom
      customDocProperties.add(key, unitsType)
      await context.sync()
    })
  } catch (error) {
    throw error
  }
}

export async function getFromCustomProperties (key) {
  try {
    await Excel.run(async (context) => {
      try {
        const customDocProperties = context.workbook.properties.custom
        const customProperty = customDocProperties.getItem(key)
        customProperty.load(['key', 'value'])
        await context.sync()

        return customProperty.value
      } catch (error){
        return null
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export function getFromPropertyBag (key) {
  try {
    if (Office.context.document.settings) {
      return Office.context.document.settings.get(key)
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export async function saveToPropertyBag (key, value) {
  try {
    if (Office.context.document.settings) {
      Office.context.document.settings.set(key, value)
      await Office.context.document.settings.saveAsync()
    }
  } catch (error) {
    throw error
  }
}

export function saveToLocalStorage (key, unitsType) {
  localStorage.setItem(key, unitsType)
}

export function getFromLocalStorage (key) {
  return localStorage.getItem(key)
}
