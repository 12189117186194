import { applyFormatting, applyOuterBorder, applyTitleFormatting, setFixedColumnWidth } from '@/components/Ribbons/Calculators/commonFormatting.js'
import { VFX_FIXED_COL_WIDTH } from '@/lib/constants'
import { antecSecondary, antecPrimary } from '@/main'
import store from '@/store'

export function btnvfxsizingcalc (event) {
  window.Excel.run(async (context) => {
    try {
      await store.dispatch('units/loadSelectedUnits')
      const flowUnits = store.getters['units/getUnits']('flow')
      const pressureUnits = store.getters['units/getUnits']('pressure')
      const pressureUnitsLabel = '(' + pressureUnits + ')'
      const flowUnitsLabel = '(' + flowUnits + ')'

      // Define the table structure
      const tableData = [
        ['VFX Quick Size Calculator', '', '', ''],
        ['', '', '', ''],
        ['Min Design Flow', '', flowUnits, ''],
        ['Max Design Flow', '', flowUnits, ''],
        ['', '', '', ''],
        ['\u26A0' + ' Design flow is within 10% of the Catalog Flow range. ', '', '', ''],
        ['Unit Size', 'Min Operating PD ' + pressureUnitsLabel, 'Min Catalog Flow ' + flowUnitsLabel, 'Max Catalog Flow ' + flowUnitsLabel],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', ''],
        ['', '', '', '']
      ]

      const worksheet = context.workbook.worksheets.getActiveWorksheet()
      const activeCell = context.workbook.getActiveCell()
      const numRows = tableData.length
      const numCols = tableData[0].length
      const targetRange = activeCell.getResizedRange(numRows - 1, numCols - 1)
      const numOutputRows = 7
      const numInputRows = numRows - numOutputRows

      const minDesignFlowCell = targetRange.getCell(2, 1)
      const maxDesignFlowCell = targetRange.getCell(3, 1)
      const outputCell = targetRange.getCell(numInputRows, 0)

      // Clear contents before loading
      targetRange.clear()
      targetRange.values = tableData

      // Style calculator background
      const whiteBackground = targetRange.getCell(1, 0).getResizedRange(5, numCols - 1)
      applyFormatting(whiteBackground, 'white')
      applyOuterBorder(targetRange, 'black', 'Thin')

      // Style title
      const titleRange = targetRange.getCell(0, 0).getResizedRange(0, numCols - 1)
      titleRange.merge()
      applyTitleFormatting(titleRange, antecPrimary, 'white')
      applyFormatting(titleRange, 'black', 'Thin')

      // Style input & output cells
      const topInputRange = minDesignFlowCell.getResizedRange(1, 0)
      applyFormatting(topInputRange, 'black', 'Medium')
      topInputRange.format.fill.color = antecSecondary
      topInputRange.format.horizontalAlignment = 'center'
      topInputRange.format.verticalAlignment = 'center'

      const bottomOutputRange = targetRange.getCell(numInputRows - 1, 0).getResizedRange(numOutputRows, numCols - 1)
      applyFormatting(bottomOutputRange, 'black', 'Thin')
      bottomOutputRange.format.horizontalAlignment = 'center'
      bottomOutputRange.format.verticalAlignment = 'center'

      // Style column width
      targetRange.format.autofitColumns()
      setFixedColumnWidth(targetRange, numCols, VFX_FIXED_COL_WIDTH)

      // Load formulas
      minDesignFlowCell.load('address')
      maxDesignFlowCell.load('address')

      await context.sync()

      const minDesignFlowAddress = minDesignFlowCell.address
      const maxDesignFlowAddress = maxDesignFlowCell.address

      const tableName = `VFXSizingCalc_${Date.now()}`
      outputCell.formulas = [[`=AntecControls.VFX.QUICKSIZE("${tableName}", ${minDesignFlowAddress}, ${maxDesignFlowAddress}`]]

      // Assign table name
      const table = worksheet.tables.add(bottomOutputRange, true)
      table.name = tableName
      table.showFilterButton = false
      table.style = null
    } catch (error) {
      console.error('Error: ', error)  // TODO how to show error
    }
    event.completed()
  })
}
