import axios from 'axios'
import tokenHandler from './tokenHandler'

export class ApiRequestHandler {
  constructor () {
    if (process.env.NODE_ENV === 'production') {
      this.baseUrl = 'https://products.priceindustries.com/api/v1/anteccontrols/'
    } else {
      this.baseUrl = 'https://products-qa.priceindustries.com/api/v1/anteccontrols/'
    }

    this.headers = {
      'Content-Type': 'application/json',
      'Authorization': ''
    }
  }

  async postData (endpoint = '', data = {}, headers = null) {
    const token = await tokenHandler.getToken()
    this.headers.Authorization = token

    if (headers) {
      headers.forEach((header) => {
        this.headers[header.key] = header.value
      })
    }

    const parsedData = JSON.parse(JSON.stringify(data))
    parsedData.input = this.parseDescriptions(parsedData)

    const request = {
      method: 'POST',
      url: this.baseUrl + endpoint,
      headers: this.headers,
      data: [parsedData]
    }

    const response = await axios(request)

    return response
  }

  parseDescriptions (data) {
    const input = JSON.parse(JSON.stringify(data.input))

    for (const key in input) {
      let value = input[key]
      if ((typeof value === 'string') && value.includes('|')) {
        value = value.split('|')[0].trim()
      }
      if (key === 'unitSize') {
        value = Number(value)
      }
      input[key] = value
    }
    return input
  }
}
