import { VFX_MODEL } from '@/lib/constants'
import store from '@/store'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'

/**
 * @customfunction
 */
export async function generateVfxPressureDrop (unitSize, airflow) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      input: {
        model: VFX_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize,
        airflow
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vfx/excel/pressureDrop', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

/**
 * @customfunction
 */
async function fetchVfxApiFlowResult (operation, unitSize) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      operation,
      input: {
        model: VFX_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vfx/excel/airflowLimits', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

export function generateVfxMaxFlow (...args) {
  return fetchVfxApiFlowResult('MaxCatalogFlow', ...args)
}

export function generateVfxMinFlow (...args) {
  return fetchVfxApiFlowResult('MinCatalogFlow', ...args)
}
