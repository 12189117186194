import { antecSecondary, antecPrimary, antecBlue } from '@/main'
import { VFX_MODEL, VFX_UNIT_SIZES, VV_MODEL, VV_UNIT_SIZES } from '@/lib/constants'
import { addSuffixToSheetName } from '@/components/common'
import store from '@/store'
import {
  applyOuterBorder,
  applyTitleFormatting,
  setDropdownAndOptionalColour,
  setValueInCenteredCell
} from '@/components/Ribbons/Calculators/commonFormatting'

export async function btnposroom (event) {
  await window.Excel.run(async (context) => {
    const sheetName = await addSuffixToSheetName(context, 'ZB - Positive Output')
    const sheet = context.workbook.worksheets.add(sheetName)
    sheet.activate()
    sheet.getRange().format.fill.color = 'white'

    setHeaders(sheet)
    setValueInCenteredCell(sheet, ['C21', 'C23', 'C25', 'C27', 'G21', 'G23', 'G25', 'G27', 'I21', 'I23', 'I25', 'I27', 'K21', 'K23', 'K25', 'K27'], '+')
    setValueInCenteredCell(sheet, ['I15', 'I17', 'E21', 'E23', 'E25', 'E27', 'M21', 'M23', 'M25', 'M27'], '=')
    setValueInCenteredCell(sheet, ['C15', 'C17', 'E15', 'E17', 'G15', 'G17'], 'x')
    loadFormulas(sheet)

    // Format Cells
    const boldHeaders = ['A2', 'A4', 'D4', 'A9', 'D9', 'A14', 'A01', 'A31', 'A20']
    boldHeaders.forEach((cell) => {
      sheet.getRange(cell).format.font.bold = true
    })

    const noColourTitles = ['B30', 'D30', 'F30', 'H30']
    noColourTitles.forEach((cell) => {
      const cellRange = sheet.getRange(cell)
      applyTitleFormatting(cellRange, 'white', 'black')
    })

    const antecPrimaryRange = sheet.getRange('B20:D28')
    applyTitleFormatting(sheet.getRange('B20'), antecPrimary, 'black')
    antecPrimaryRange.format.fill.color = antecPrimary

    const antecBlueRange = sheet.getRange('F20:J28')
    applyTitleFormatting(sheet.getRange('F20'), antecBlue, 'black')
    antecBlueRange.format.fill.color = antecBlue

    const lightBlueRange = sheet.getRange('L20:L28')
    applyTitleFormatting(sheet.getRange('L20'), '#9DB2C5', 'black')
    lightBlueRange.format.fill.color = '#9DB2C5'

    const antecGrayRange = sheet.getRange('N20:N28')
    applyTitleFormatting(sheet.getRange('N20'), antecSecondary, 'black')
    antecGrayRange.format.fill.color = antecSecondary

    const formulaCellFooters = ['B18:J18', 'B16:J16', 'B22:J22', 'B24:J24', 'B26:J26', 'B28:J28']
    formulaCellFooters.forEach((cell) => {
      const cellRange = sheet.getRange(cell)
      cellRange.format.font.size = 8
      cellRange.format.horizontalAlignment = 'Center'
      cellRange.format.verticalAlignment = window.Excel.VerticalAlignment.top
    })

    const mergeCells = ['B20:D20', 'B2:F2', 'D4:F4', 'D9:F9', 'D12:F12', 'D11:F11', 'D10:F10', 'F20:J20']
    mergeCells.forEach((cell) => {
      const cellRange = sheet.getRange(cell)
      cellRange.merge()
    })

    const setWidthColumns = ['D:D', 'B:B', 'F:F', 'H:H', 'J:J', 'L:L', 'N:N']
    setWidthColumns.forEach((cell) => {
      const cellRange = sheet.getRange(cell)
      cellRange.format.columnWidth = 70
      cellRange.format.wrapText = true
    })

    const autoFitColumns = ['C:C', 'A:A', 'E:E', 'G:G', 'I:I', 'K:K', 'M:M']
    autoFitColumns.forEach((cell) => {
      const cellRange = sheet.getRange(cell)
      cellRange.format.autofitColumns()
    })

    const inputFields = ['B2:F2', 'B5', 'B6', 'B7', 'B10', 'B11', 'B12', 'H5', 'H6', 'H10', 'H11', 'H12', 'B31', 'D31', 'F31', 'H31']
    const bordersRanges = [
      'B15', 'D15', 'F15', 'H15', 'J15', 'B17',
      'D17', 'F17', 'H17', 'J17', 'B21', 'D21',
      'F21', 'H21', 'J21', 'L21', 'N21', 'B23',
      'D23', 'F23', 'H23', 'J23', 'L23', 'N23',
      'B25', 'D25', 'F25', 'H25', 'J25', 'L25',
      'N25', 'B27', 'D27', 'F27', 'H27', 'J27',
      'L27', 'N27'].concat(inputFields)

    bordersRanges.forEach((range) => {
      const borderRange = sheet.getRange(range)
      applyOuterBorder(borderRange, 'black', window.Excel.BorderWeight.medium)
      borderRange.format.horizontalAlignment = 'Center'

      if (inputFields.includes(range)) {
        borderRange.format.fill.color = antecSecondary
      } else {
        borderRange.format.fill.color = 'white'
      }
    })

    const typeRange = sheet.getRange('B31')
    setDropdownAndOptionalColour(typeRange, [VV_MODEL, VFX_MODEL].join(','))

    const hiddenCellsStartRow = 35
    VV_UNIT_SIZES.forEach(((unitSize, index) => {
      const cellRange = sheet.getRange('A' + (index + hiddenCellsStartRow))
      cellRange.values = [[unitSize]]
      cellRange.rowHidden = true
    }))

    VFX_UNIT_SIZES.forEach(((unitSize, index) => {
      const cellRange = sheet.getRange('B' + (index + hiddenCellsStartRow))
      cellRange.values = [[unitSize]]
      cellRange.rowHidden = true
    }))

    const sizeRange = sheet.getRange('D31')
    setDropdownAndOptionalColour(sizeRange, `=IF(B31="${VV_MODEL}", A${hiddenCellsStartRow}:A${VV_UNIT_SIZES.length + hiddenCellsStartRow}, B${hiddenCellsStartRow}:B${VFX_UNIT_SIZES.length + hiddenCellsStartRow})`)

    return await context.sync()
  })
  event.completed()
}

function setHeaders (sheet) {
  sheet.getRange('A2').values = [['Room Name:']]

  sheet.getRange('A4').values = [[`Room Dimensions (${store.getters['units/getUnits']('length')})`]]
  sheet.getRange('A5').values = [['Room Length']]
  sheet.getRange('A6').values = [['Room Width']]
  sheet.getRange('A7').values = [['Room Height']]

  sheet.getRange('D4').values = [['Air Change Rates (ACH)']]
  sheet.getRange('D5').values = [['Occupied']]
  sheet.getRange('D6').values = [['Unoccupied']]

  sheet.getRange('A9').values = [[`Supply Airflows (${store.getters['units/getUnits']('flow')})`]]
  sheet.getRange('A10').values = [['Max Cooling Airflow']]
  sheet.getRange('A11').values = [['Min Cooling Airflow']]
  sheet.getRange('A12').values = [['Constant Volume Supply']]

  sheet.getRange('D9').values = [[`Exhaust Airflows (${store.getters['units/getUnits']('flow')})`]]
  sheet.getRange('D10').values = [['Total Hood Max']]
  sheet.getRange('D11').values = [['Total Hood Min']]
  sheet.getRange('D12').values = [['Constant Volume Exhaust']]

  sheet.getRange('A14').values = [['Preliminary Calculations']]

  // Occupied Ventilation Airflow Calculation
  sheet.getRange('A15').values = [['Occupied Ventilation Airflow']]
  sheet.getRange('B16').values = [['OCC # of ACH']]
  sheet.getRange('D16').values = [['Length']]
  sheet.getRange('F16').values = [['Width']]
  sheet.getRange('H16').values = [['Height']]
  sheet.getRange('J16').values = [[`OCC Total Flow (${store.getters['units/getUnits']('flow')})`]]

  // Unoccupied Ventilation Airflow Calculation
  sheet.getRange('A17').values = [['Unoccupied Ventilation Airflow']]
  sheet.getRange('B18').values = [['UNOCC # of ACH']]
  sheet.getRange('D18').values = [['Length']]
  sheet.getRange('F18').values = [['Width']]
  sheet.getRange('H18').values = [['Height']]
  sheet.getRange('J18').values = [[`OCC Total Flow (${store.getters['units/getUnits']('flow')})`]]

  sheet.getRange('A20').values = [['Load Condition']]

  // Load Condition Rows
  sheet.getRange('A21').values = [['Thermal Demand']]
  sheet.getRange('A23').values = [['Fume Hood Demand']]
  sheet.getRange('A25').values = [['Occupied Ventilation Demand']]
  sheet.getRange('A27').values = [['Unoccupied Ventilation Demand']]

  // Supply and Exhaust Section
  sheet.getRange('B20').values = [['Supply']]

  sheet.getRange('B22').values = [['Max Cooling Airflow']]
  sheet.getRange('D22').values = [['CV Supply']]
  sheet.getRange('B24').values = [['Supply Airflow for Fume Hood Max']]
  sheet.getRange('D24').values = [['CV Supply']]
  sheet.getRange('B26').values = [['Supply Valve Minimum']]
  sheet.getRange('D26').values = [['CV Supply']]
  sheet.getRange('B28').values = [['Supply Valve Minimum']]
  sheet.getRange('D28').values = [['CV Supply']]

  sheet.getRange('F20').values = [['Exhaust']]

  sheet.getRange('F22').values = [['Total Fume Hood Min']]
  sheet.getRange('H22').values = [['CV Exhaust']]
  sheet.getRange('J22').values = [['General Exhaust\nMaximum']]
  sheet.getRange('F24').values = [['Total Fume Hood Max']]
  sheet.getRange('H24').values = [['CV Exhaust']]
  sheet.getRange('J24').values = [['General Exhaust\nMinimum']]
  sheet.getRange('F26').values = [['Total Fume Hood Min']]
  sheet.getRange('H26').values = [['CV Exhaust']]
  sheet.getRange('J26').values = [['General Exhaust\nMinimum']]
  sheet.getRange('F28').values = [['Total Fume Hood Min']]
  sheet.getRange('H28').values = [['CV Exhaust']]
  sheet.getRange('J28').values = [['General Exhaust\nMinimum']]

  sheet.getRange('L20').values = [['Offset']]
  sheet.getRange('N20').values = [['Total Flow']]

  sheet.getRange('A31').values = [['General Exhaust Valve']]

  // General Exhaust Valve Rows
  sheet.getRange('B30').values = [['Type']]
  sheet.getRange('D30').values = [['Size']]
  sheet.getRange('F30').values = [['Minimum']]
  sheet.getRange('H30').values = [['Maximum']]
}

function loadFormulas (sheet) {
  sheet.getRange('B15').formulas = [['=H5']]
  sheet.getRange('D15').formulas = [['=B5']]
  sheet.getRange('F15').formulas = [['=B6']]
  sheet.getRange('H15').formulas = [['=B7']]
  sheet.getRange('J15').formulas = (store.getters['units/isImperial']) ? [['=(B15*D15*F15*H15)/60']] : [['=(B15*D15*F15*H15*1000)/3600']]
  sheet.getRange('B17').formulas = [['=H6']]
  sheet.getRange('D17').formulas = [['=B5']]
  sheet.getRange('F17').formulas = [['=B6']]
  sheet.getRange('H17').formulas = [['=B7']]
  sheet.getRange('J17').formulas = (store.getters['units/isImperial']) ? [['=(B17*D17*F17*H17)/60']] : [['=(B17*D17*F17*H17*1000)/3600']]

  // Load Condition
  sheet.getRange('B21').formulas = [['=B10']]
  sheet.getRange('B23').formulas = [['=N23-D23']]
  sheet.getRange('B25').formulas = [['=N25-D25']]
  sheet.getRange('B27').formulas = [['=N27-D27']]

  sheet.getRange('D21').formulas = [['=B12']]
  sheet.getRange('D23').formulas = [['=B12']]
  sheet.getRange('D25').formulas = [['=B12']]
  sheet.getRange('D27').formulas = [['=B12']]

  sheet.getRange('F21').formulas = [['=H11']]
  sheet.getRange('F23').formulas = [['=H10']]
  sheet.getRange('F25').formulas = [['=H11']]
  sheet.getRange('F27').formulas = [['=H11']]

  sheet.getRange('H21').formulas = [['=H12']]
  sheet.getRange('H23').formulas = [['=H12']]
  sheet.getRange('H25').formulas = [['=H12']]
  sheet.getRange('H27').formulas = [['=H12']]

  sheet.getRange('J21').formulas = [['=N21-H21-F21-L21']]
  sheet.getRange('J23').formulas = [['=F31']]
  sheet.getRange('J25').formulas = [['=N25-F25-H25-L25']]
  sheet.getRange('J27').formulas = [['=N27-F27-H27-L27']]

  sheet.getRange('L21').formulas = [['=IF($B$21>$F$23,$B$21*0.1,IF($F$23>$N$25,$F$23*0.1,$N$25*0.1))']]
  sheet.getRange('L23').formulas = [['=IF($B$21>$F$23,$B$21*0.1,IF($F$23>$N$25,$F$23*0.1,$N$25*0.1))']]
  sheet.getRange('L25').formulas = [['=IF($B$21>$F$23,$B$21*0.1,IF($F$23>$N$25,$F$23*0.1,$N$25*0.1))']]
  sheet.getRange('L27').formulas = [['=IF($B$21>$F$23,$B$21*0.1,IF($F$23>$N$25,$F$23*0.1,$N$25*0.1))']]

  sheet.getRange('N21').formulas = [['=SUM(B21+D21)']]
  sheet.getRange('N23').formulas = [['=SUM(F23+H23+J23+L23)']]
  sheet.getRange('N25').formulas = [['=J15']]
  sheet.getRange('N27').formulas = [['=J17']]
}
