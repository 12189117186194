export function applyOuterBorder (range, color, weight) {
  ['EdgeTop', 'EdgeBottom', 'EdgeLeft', 'EdgeRight'].forEach((edge) => {
    const borderItem = range.format.borders.getItem(edge)
    borderItem.style = 'Continuous'
    borderItem.weight = weight
    borderItem.color = color
  })
}

export function applyTitleFormatting (range, fillColor, fontColor) {
  range.format.fill.color = fillColor
  range.format.font.bold = true
  range.format.horizontalAlignment = 'center'
  range.format.font.color = fontColor
}

export function setFixedColumnWidth (range, numCols, width) {
  for (let i = 0; i < numCols - 1; i++) {
    const column = range.getColumn(i)
    column.format.columnWidth = width
  }
}

export function applyFormatting (range, color, weight) {
  const borders = ['EdgeTop', 'EdgeBottom', 'EdgeLeft', 'EdgeRight', 'InsideHorizontal', 'InsideVertical']
  borders.forEach((border) => {
    const borderItem = range.format.borders.getItem(border)
    borderItem.style = 'Continuous'
    borderItem.weight = weight
    borderItem.color = color
  })
}

export function setValueInCenteredCell (sheet, textRange, value) {
  textRange.forEach((cell) => {
    const cellRange = sheet.getRange(cell)
    cellRange.values = [[value]]
    cellRange.format.horizontalAlignment = 'center'
  })
}

export function clearCellRange (dataRange) {
  for (let numRow = 0; numRow < dataRange.rowCount; numRow++) {
    for (let numCol = 0; numCol < dataRange.columnCount; numCol++) {
      if (!((numRow === 0) && (numCol === 0))) {
        dataRange.getCell(numRow, numCol).values = [['']]
      }
    }
  }
}

export function setDropdownAndOptionalColour (range, dropdownOptions, colour = null) {
  if (colour) {
    range.format.fill.color = colour
  }

  if (dropdownOptions === null) {
    range.dataValidation.rule = {
      list: {
        inCellDropDown: false
      }
    }
  } else {
    range.dataValidation.rule = {
      list: {
        inCellDropDown: true,
        source: dropdownOptions
      }
    }
  }
}
