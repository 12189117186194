import { VFX_MODEL } from '@/lib/constants'
import store from '@/store'
import { clearCellRange } from '@/components/Ribbons/Calculators/commonFormatting'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'

/**
 * @customfunction
 */
export async function generateVfxQuickSize (tableName, minAirflow, maxAirflow) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      input: {
        model: VFX_MODEL,
        units: store.getters['units/getSelectedUnits'],
        minAirflow,
        maxAirflow
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vfx/excel/quickSize', data)

  const parsedResult = JSON.parse(response.data[0].result)
  const showResults = (response.data[0].error === null) && parsedResult

  await Excel.run(async (context) => {
    const worksheet = context.workbook.worksheets.getActiveWorksheet()
    const table = worksheet.tables.getItem(tableName)
    const dataRange = table.getDataBodyRange()
    dataRange.load(['rowCount', 'columnCount'])

    await context.sync()

    // Clear specific cells
    clearCellRange(dataRange)

    if (showResults) {
      parsedResult.forEach((result, index) => {
        if (index !== 0) {
          dataRange.getCell(index, 0).values = [[result.UnitSize]]
        }
        dataRange.getCell(index, 1).values = [[result.PressureDrop]]

        const minCatalogAirflow = dataRange.getCell(index, 2)
        const maxCatalogAirflow = dataRange.getCell(index, 3)

        minCatalogAirflow.values = [[result.MinCatalogAirflow]]
        maxCatalogAirflow.values = [[result.MaxCatalogAirflow]]

        // Conditional formatting
        if ((minAirflow >= minCatalogAirflow.values[0][0]) && (minAirflow <= (minCatalogAirflow.values[0][0] * 1.1))) { // Within 10% of min catalog flow
          minCatalogAirflow.values = '\u26A0 ' + minCatalogAirflow.values[0][0]
        } else {
          minCatalogAirflow.values = minCatalogAirflow.values[0][0]
        }

        if ((maxAirflow <= maxCatalogAirflow.values[0][0]) && (maxAirflow >= (maxCatalogAirflow.values[0][0] * 0.9))) { // Within 10% of max catalog airflow
          maxCatalogAirflow.values = '\u26A0 ' + maxCatalogAirflow.values[0][0]
        } else {
          maxCatalogAirflow.values = maxCatalogAirflow.values[0][0]
        }
      })
    }
  })

  if (showResults) {
    return parseInt(parsedResult[0].UnitSize)
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}
