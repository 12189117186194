import store from '@/store'
import { VFX_MODEL } from '@/lib/constants'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'

/**
 * @customfunction
 */
async function fetchVFXApiResult (operation, unitSize, maxAirflow, pressureDrop) {
  console.log(pressureDrop) // TODO why does a value still return when pressureDrop undefined -- check API
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      operation,
      input: {
        model: VFX_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize,
        maxAirflow,
        pressureDrop
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vfx/excel/soundPower', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

export function generateVfxMaxDischarge (...args) {
  return fetchVFXApiResult('MaxDischargeSoundPowerLevels', ...args)
}

export function generateVfxMaxRadiated (...args) {
  return fetchVFXApiResult('MaxRadiatedSoundPowerLevels', ...args)
}

export function generateVfxMaxInlet (...args) {
  return fetchVFXApiResult('MaxInletSoundPowerLevels', ...args)
}
