import { IMPERIAL_UNITS, UNITS_TYPE_STORAGE_LABEL, UNITS } from '@/lib/constants'
import * as helperFunctions from '@/store/helpers'

export default {
  namespaced: true,
  state: {
    unitsType: IMPERIAL_UNITS
  },
  getters: {
    getSelectedUnits (state) {
      return state.unitsType
    },
    isImperial (state) {
      return state.unitsType === IMPERIAL_UNITS
    },
    getUnits (state) {
      return (unitType) => {
        const units = state.unitsType === IMPERIAL_UNITS ? UNITS.IMPERIAL : UNITS.METRIC
        return units[unitType]
      }
    }
  },
  actions: {
    async loadSelectedUnits ({ commit }) {
      let loadedUnits = await helperFunctions.getFromCustomProperties(UNITS_TYPE_STORAGE_LABEL)

      if (!loadedUnits) {
        loadedUnits = helperFunctions.getFromPropertyBag(UNITS_TYPE_STORAGE_LABEL)
      }

      if (!loadedUnits) {
        loadedUnits = helperFunctions.getFromLocalStorage(UNITS_TYPE_STORAGE_LABEL)
      }

      if (!loadedUnits) { // Default
        loadedUnits = IMPERIAL_UNITS
      }

      commit('setUnitsType', loadedUnits)
      Promise.resolve()
    },
    async saveSelectedUnits ({ commit }, unitsType) {
      try {
        await helperFunctions.saveToCustomProperties(UNITS_TYPE_STORAGE_LABEL, unitsType)
        await helperFunctions.saveToPropertyBag(UNITS_TYPE_STORAGE_LABEL, unitsType)
        await helperFunctions.saveToLocalStorage(UNITS_TYPE_STORAGE_LABEL, unitsType)
      } catch (error) {
        console.log(error)
      }
      commit('setUnitsType', unitsType)
      Promise.resolve()
    },
    setSelectedUnits ({ commit }, unitsType) {
      commit('setUnitsType', unitsType)
      Promise.resolve()
    }
  },
  mutations: {
    setUnitsType (state, unitsType) {
      if (unitsType !== null) {
        state.unitsType = unitsType
      }
    }
  }
}
