import store from '@/store'
import { IMPERIAL_UNITS, METRIC_UNITS } from '@/lib/constants'

export function btnweb (args) {
  try {
    window.open('https://anteccontrols.com', '_blank')
  } catch (error) {
    console.error(error)
  }
  args.completed()
}

export async function btnImperial (args) {
  await store.dispatch('units/saveSelectedUnits', IMPERIAL_UNITS)
  await store.dispatch('units/loadSelectedUnits')
  args.completed()
}

export async function btnMetric (args) {
  await store.dispatch('units/saveSelectedUnits', METRIC_UNITS)
  await store.dispatch('units/loadSelectedUnits')
  args.completed()
}
