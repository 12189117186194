<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang='scss'>
@import '/src/styles/common';
@import '/src/styles/mainPageStyling';
@import '/src/styles/topNavigationStyling';
@import '/src/styles/variables';
@import '/src/styles/popupStyling';
@import '/src/styles/excelHelpStyling';
@import '/src/styles/updatesListStyling';
@import '/src/styles/contactPageStyling';
@import '/src/styles/eulaStyling.scss';
</style>
