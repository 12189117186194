import { VV_MODEL } from '@/lib/constants'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'
import store from '@/store'

/**
 * @customfunction
 */
export async function generateVvPressureDrop (staticInletPressure, staticDownstreamPressure) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      input: {
        model: VV_MODEL,
        units: store.getters['units/getSelectedUnits'],
        staticInletPressure,
        staticDownstreamPressure
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vv/excel/pressureDrop', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}
