import axios from 'axios'

class TokenHandler {
  constructor () {
    if (TokenHandler.instance) {
      return TokenHandler.instance
    }

    TokenHandler.instance = this

    this.token = ''
    this.expDate = new Date()
  }

  getToken () {
    if (this.token && (this.expDate > new Date())){
      return this.token
    }

    return this.fetchToken()
  }

  async fetchToken () {
    const details = {
      grant_type: 'client_credentials',
      client_id: 'et_excel_client',
      client_secret: 'yVcJcsaU2xCEVwfRbPjzXGyz',
      scope: 'AntecControlsApi'
    }

    const formBody = []
    for (const property in details) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(details[property])
      formBody.push(encodedKey + '=' + encodedValue)
    }

    const formBodyStr = formBody.join('&')

    const authURl = (process.env.NODE_ENV === 'production') ? 'https://sso.priceindustries.com/am/oauth2/access_token' : 'https://sso-qa.priceindustries.com/am/oauth2/access_token'
    const request = {
      method: 'POST',
      url: authURl,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
      data: formBodyStr
    }

    const tokenResponse = await axios(request)
    if (tokenResponse.data) {
      this.token = 'Bearer ' + tokenResponse.data.access_token
      this.expDate = new Date(new Date().getTime() + tokenResponse.data.expires_in * 1000)
    }

    return this.token
  }
}

const instance = new TokenHandler()

export default instance
