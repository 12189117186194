import store from '@/store'
import { VV_MODEL } from '@/lib/constants'
import { ApiRequestHandler } from '@/apiHelper/apiRequestHandler'

/**
 * @customfunction
 */
async function fetchVVApiResult (operation, unitSize, maxAirflow, pressureDrop) {
  await store.dispatch('units/loadSelectedUnits')
  const data =
    {
      operation,
      input: {
        model: VV_MODEL,
        units: store.getters['units/getSelectedUnits'],
        unitSize,
        maxAirflow,
        pressureDrop
      }
    }

  const apiHandler = new ApiRequestHandler()
  const response = await apiHandler.postData('vv/excel/soundPower', data)

  if (response.data[0].error === null) {
    return response.data[0].result
  } else {
    console.error(response.data[0].error)
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, response.data[0].error)
  }
}

export function generateVvMaxDischarge (...args) {
  return fetchVVApiResult('MaxDischargeSoundPowerLevels', ...args)
}

export function generateVvMaxRadiated (...args) {
  return fetchVVApiResult('MaxRadiatedSoundPowerLevels', ...args)
}

export function generateVvMaxInlet (...args) {
  return fetchVVApiResult('MaxInletSoundPowerLevels', ...args)
}
